<template>
  <CFooter>
    <div>
      <a href="http://stormhavenstudios.com" target="_blank">Stormhaven Studios LLC</a>
      <span class="ml-1">&copy; 2020</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="https://coreui.io/vue" target="_blank">CoreUI for Vue</a>
    </div>
  </CFooter>
</template>

<script>
  export default {
    name: 'TheFooter'
  }
</script>
