<template>
  <CSidebar
          fixed
          :minimize="minimize"
          :show.sync="show"
  >
    <CSidebarBrand to="/">
      <img src="img/brand/heading.png" class="c-sidebar-brand-full"/>
      <img src="img/brand/logo-tiny.png" class="c-sidebar-brand-minimized"/>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="menu"/>
    <CSidebarMinimizer
            class="d-md-down-none"
            @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
  export default {
    name: 'TheSidebar',
    data () {
      return {
        minimize: false,
        menu: [],
        show: 'responsive'
      }
    },
    methods: {
      setMenu() {
        this.menu = [
          {
            _name: 'CSidebarNav',
            _children: [
              {
                  _name: 'CSidebarNavItem',
                  name: 'Dashboard',
                  to: '/dashboard',
                  icon: 'cil-speedometer'
              },
              {
                  _name: 'CSidebarNavItem',
                  name: 'Environments',
                  to: '/environments',
                  icon: 'cil-sitemap'
              }
            ]
          }
        ];

        const r = parseInt(localStorage.roles);

        if ((r & 1) !== 0) {
          this.addGM();
        }
        
        if ((r & 2) !== 0) {
          this.addAcc();
        }

      },
      addGM() {
        this.menu[0]._children.push({
              _name: 'CSidebarNavTitle',
              _children: ['Users']
          });

        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Players',
            to: '/users/players',
            icon: 'cil-people'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Characters',
            to: '/users/characters',
            icon: 'cil-casino'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavTitle',
            _children: ['Embers']
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Worlds',
            to: '/embers/worlds',
            icon: 'cil-globe-alt'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Zones',
            to: '/embers/zones',
            icon: 'cil-terrain'
        });
      },
      addAcc() {
        this.menu[0]._children.push({
            _name: 'CSidebarNavTitle',
            _children: ['GM']
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Accounts',
            to: '/gm/accounts',
            icon: 'cil-address-book'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Products',
            to: '/gm/products',
            icon: 'cil-cart'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Configs',
            to: '/gm/configs',
            icon: 'cil-settings'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Game Versions',
            to: '/gm/versions/client',
            icon: 'cil-code'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Patcher Versions',
            to: '/gm/versions/patcher',
            icon: 'cil-puzzle'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Flags',
            to: '/gm/flags',
            icon: 'cil-flag-alt'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavTitle',
            _children: ['Reports']
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Subscriptions',
            to: '/reports/subscriptions',
            icon: 'cil-money'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Sales',
            to: '/reports/sales',
            icon: 'cil-money'
        });
        this.menu[0]._children.push({
            _name: 'CSidebarNavItem',
            name: 'Surveys',
            to: '/reports/surveys',
            icon: 'cil-short-text'
        });
      }
    },
    mounted () {
      this.$root.$on('toggle-sidebar', () => {
        const sidebarOpened = this.show === true || this.show === 'responsive'
        this.show = sidebarOpened ? false : 'responsive'
      })
      this.$root.$on('toggle-sidebar-mobile', () => {
        const sidebarClosed = this.show === 'responsive' || this.show === false
        this.show = sidebarClosed ? true : 'responsive'
      })
    },
    created() {
        this.setMenu();
    }
  }
</script>
