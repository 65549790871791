<template>
  <CDropdown
          inNav
          class="c-header-nav-items"
          placement="bottom-end"
          add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        {{ username }}
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem @click="handleLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
  export default {
    name: 'TheHeaderDropdownAccnt',
    data () {
      return {
        itemsCount: 42,
        username: localStorage.getItem("username")
      }
    },
    methods: {
      handleLogout(e){
        e.preventDefault();
        localStorage.removeItem('username');
        localStorage.removeItem('roles');
        localStorage.removeItem('jwt');

        this.$router.push('/Login');
      }
    }
  }
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
