<template>
  <div class="c-app">
    <TheSidebar/>
    <div class="c-wrapper">
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view :key="currentEnv"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </div>
    <CModal :title="msgTitle" :color="msgColor" :show.sync="msgModal">
      {{ msg }}
    </CModal>
  </div>
</template>

<script>
  import TheSidebar from './TheSidebar'
  import TheHeader from './TheHeader'
  import TheFooter from './TheFooter'

  export default {
    name: 'TheContainer',
    data () {
      return {
        msgModal: false,
        msgTitle: '',
        msg: '',
        msgColor: '',
        currentEnv: localStorage.env
      }
    },
    mounted: function () {
      this.$root.$on('sendMsg', (title, color, msg) => {
        this.msgTitle = title;
        this.msgColor = color;
        this.msg = msg;
        this.msgModal = true;
      });
      this.$root.$on('onErr', (error) => {
        this.msgTitle = `API Error (${error.status})`;
        this.msgColor = "danger";
        this.msg = (!error.body.error) ? "An Unknown Error Has Occurred" : error.body.error;
        this.msgModal = true;
      });
      this.$root.$on('onUpdateEnv', (env) => {
        this.currentEnv = env;
        localStorage.env = env;

        if (this.$route.name === "World") {
          this.$router.push({ name: 'Worlds' });
        } else if (this.$route.name === "Zone") {
          this.$router.push({ name: 'Zones' });
        } else if (this.$route.name === "Player") {
          this.$router.push({ name: 'Players' });
        } else if (this.$route.name === "Character") {
          this.$router.push({ name: 'Characters' });
        }

      })
    },
    components: {
      TheSidebar,
      TheHeader,
      TheFooter
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
