<template>
  <CHeader fixed with-subheader light>
    <CToggler
            in-header
            class="ml-3 d-lg-none"
            v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
            in-header
            class="ml-3 d-md-down-none"
            v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
            class="mx-auto d-lg-none"
            src="img/brand/sol-logo.png"
            height="24"
            alt="Stormhaven Studios"
    />

    <CHeaderNav class="d-lg-down-none mr-auto align-content-center">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/environments">
          Environments
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CDropdown
                size="sm"
                :toggler-text="currentEnv"
                :color="pillClr"
                add-toggler-classes="customDropDown"
                class="m-0">
          <CDropdownItem @click="updateEnv('dev')" >Dev</CDropdownItem>
          <CDropdownItem @click="updateEnv('qa')" >QA</CDropdownItem>
          <CDropdownItem @click="updateEnv('live')" >Live</CDropdownItem>
        </CDropdown>
      </CHeaderNavItem>
      <span style="padding-left: 45px; font-weight: bolder;">{{ this.currentHost }}</span>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter  class="border-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
  import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

  export default {
    name: 'TheHeader',
    data(){
      return {
        currentEnv: localStorage.env,
        currentHost: ''
      }
    },
    mounted: function () {
      this.$root.$on('onUpdateEnv', (env) => {
        if (env !== undefined) {
          this.currentEnv = env;
          this.currentHost = this.$config.zc[env];
        }
      })
    },
    computed: {
      pillClr: function () {
        if (this.currentEnv === "qa" ) { return "warning"; } else
        if (this.currentEnv === "live" ) { return "danger"; } else
        { return "success"; }
      }
    },
    components: {
      TheHeaderDropdownAccnt
    },
    methods: {
      updateEnv(env) {
        this.$root.$emit('onUpdateEnv', env);
      }
    },
    created() {
      this.currentHost = this.$config.zc[localStorage.env];
    },
  }
</script>
